<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { fetchStats, saveRedirectTarget } from '@/api'
import { hasLoggedIn } from '@/helpers/account'
import { getCookie } from '@/helpers/cookie'
import {
  getSearchPath,
  isValidSearchPath,
  getBetaSearchPath,
} from '@/helpers/search'
import { isAcceptedVersionNumberChanged } from '@/helpers/privacy'
import { registerMatomoEvent } from '@/helpers/matomo'
import GdprBanner from '@/templates/GdprBanner'
import { SEARCH_QUERY_LIMIT } from '@/config'

export default {
  components: {
    AlertModal: () => import('@/templates/AlertModal'),
    Confirmation: () => import('epmc-patterns/components/v2/Confirmation'),
    Action: () => import('epmc-patterns/components/v2/Action'),
    FeedbackModal: () => import('@/templates/FeedbackModal'),
    GdprBanner,
    Modal: () => import('epmc-patterns/components/v2/Modal'),
    SearchTermCharacterLimitModal: () =>
      import('@/templates/SearchTermCharacterLimitModal'),
  },
  data() {
    return {
      articleCount: {
        query: 'SRC:*',
        count: '',
      },
      isHome: location.pathname === '/',
      isSearchPage: isValidSearchPath(location.pathname.replace('/', '')),
      isBetaSearchPage:
        location.pathname.replace('/', '') === getBetaSearchPath(),
      searchText: '',
      showModal: false,
      showCharacterLimitModal: false,
      openedAlert: null,
      showGdprBanner: false,
      showFeedbackModal: false,
      showFeedbackConfirmation: false,
      searchTermLimit: SEARCH_QUERY_LIMIT,
    }
  },
  computed: mapState('searchSessions', ['query']),
  watch: {
    $route(to) {
      const {
        name,
        query: { query, action },
      } = to
      if (name === 'home') {
        this.searchText = ''
        this.changeQuery('')
      } else if (isValidSearchPath(name) && query) {
        this.searchText = query
        this.changeQuery(query)
      } else if (this.query) {
        this.searchText = this.query
      }

      if (isValidSearchPath(name) && action === 'save_alert') {
        if (query.length > SEARCH_QUERY_LIMIT) {
          this.showCharacterLimitModal = true
          return
        }
        this.showModal = true
        // open alert possibe only if login
        if (hasLoggedIn()) {
          this.getAndClearAlertModal().then((alertModal) => {
            if (alertModal.alert) {
              this.openedAlert = alertModal.alert
            } else {
              this.openedAlert = {
                query,
                name: query,
              }
            }
          })
        }
        saveRedirectTarget()
      }

      if (to.name === getBetaSearchPath()) {
        this.isBetaSearchPage = true
      }
    },
  },
  async created() {
    const { articleCount } = this
    const response = await fetchStats(articleCount.query)
    articleCount.count = response.hitCount.toLocaleString()

    if (this.query) {
      this.searchText = this.query
    }
    const isChanged = await isAcceptedVersionNumberChanged()
    const epmcPrivacyNoticeAgreed = getCookie('epmcPrivacyNoticeAgreed')
    this.showGdprBanner =
      epmcPrivacyNoticeAgreed === 'true' && !isChanged ? false : true
  },
  methods: {
    ...mapMutations('searchSessions', ['changeQuery']),
    ...mapActions('alert', ['getAndClearAlertModal']),
    search() {
      const searchRoute = getSearchPath()
      const query = encodeURIComponent(this.searchText || 'SRC:*')
      location.href = `/${searchRoute}?query=${query}`
      // registerMatomoEvent('Common', 'Search button', query)
    },
    openArticleCountLink() {
      const searchRoute = getSearchPath()
      location.href = `/${searchRoute}?query=${this.articleCount.query}`
    },
    // only on search page
    showSaveAlert() {
      registerMatomoEvent(
        'Saved alerts',
        'Click "Save & create alerts"',
        'Save & create alert'
      )

      const { query } = this
      if (query.length > SEARCH_QUERY_LIMIT) {
        this.showCharacterLimitModal = true
        return
      }
      this.showModal = true
      if (hasLoggedIn()) {
        this.openedAlert = {
          query,
          name: query,
        }
      }
    },
    registerRedirection() {
      saveRedirectTarget(this.$route.fullPath.split('?')[1])
    },
    onFeedbackModalClosed(e) {
      if (e && e.success) {
        this.showFeedbackConfirmation = true
      }
      this.showFeedbackModal = false
    },
    onFeedbackConfirmationClosed() {
      this.showFeedbackConfirmation = false
    },
    registerMatomoEvent,
    openSurveyLink() {
      window.open('https://www.surveymonkey.co.uk/r/H3DC7DK')
    },
  },
}
</script>
<template>
  <div>
    <!-- <div id="warning-banner-container"></div> -->
    <div id="search-panel" :class="{ 'home-search-panel': isHome }">
      <div class="grid-row">
        <div class="col-16">
          <h1>
            <label for="banner--search-input" class="semi-bold"
              >Search life-sciences literature
              <span id="article-count" class="regular">
                <action
                  id="article-count-link"
                  @click.prevent="openArticleCountLink"
                >
                  ({{ articleCount.count }}
                </action>
                articles, preprints and more)</span
              >
            </label>
          </h1>
          <div id="search-bar">
            <form id="search-bar-inputs" method="get" action="/search">
              <input
                id="banner--search-input"
                v-model.trim="searchText"
                name="query"
                type="text"
                @keyup.enter="search"
              /><button
                id="banner--search-button"
                class="regular"
                type="submit"
                @click.prevent="search"
              >
                <i class="fa fa-search"></i
                ><span id="search-btn-text">&nbsp;Search</span>
              </button>
              <button
                v-if="isSearchPage && !isBetaSearchPage"
                id="banner--alert-button"
                class="secondary"
                @click.prevent="showSaveAlert"
              >
                <span>Save & create alert</span><i class="fas fa-bell" />
              </button>
            </form>
            <div>
              <a
                id="banner--advanced-search"
                href="/advancesearch"
                @click="
                  registerMatomoEvent(
                    'Common',
                    'Advanced search link',
                    'Clicked advanced search link'
                  )
                "
                >Advanced search</a
              >
              <span id="separator">&nbsp;|&nbsp;</span>
              <a id="betaAdvanceSearchLink" href="/betaAdvanceSearch"
                >Try the new Advanced search!</a
              >
            </div>
          </div>
          <div v-if="isHome" id="features">
            <div class="feature">
              <div>
                <img
                  src="@/assets/innovative-features.svg"
                  alt="Innovative features"
                />
              </div>
              <h2>Innovative features</h2>
              <p>
                Intuitive and powerful search tools, linked resources and author
                services help you stay on top of the cutting edge of science. To
                learn more, see
                <a
                  href="/About#why-use-europe-pmc"
                  @click="
                    registerMatomoEvent(
                      'Home',
                      'Innovative features',
                      'Why use EPMC?'
                    )
                  "
                  >Why use Europe PMC</a
                >.
              </p>
            </div>
            <div class="feature">
              <div>
                <img
                  src="@/assets/comprehensive-search.svg"
                  alt="Comprehensive search"
                />
              </div>
              <h2>Comprehensive search</h2>
              <p>
                Search life sciences literature from trusted sources around the
                globe, all in one search, accessible by anyone anywhere, for
                free. Learn more
                <a
                  href="/About"
                  @click="
                    registerMatomoEvent(
                      'Home',
                      'Comprehensive search',
                      'About EPMC'
                    )
                  "
                  >About Europe PMC</a
                >.
              </p>
            </div>
            <div class="feature">
              <div>
                <img
                  src="@/assets/trusted-partnerships.svg"
                  alt="Trusted partnerships"
                />
              </div>
              <h2>Trusted partnerships</h2>
              <p>
                Europe PMC is the partner of
                <a href="//www.ncbi.nlm.nih.gov/pmc/about/pmci/"
                  >PubMed Central (PMC)</a
                >, an
                <a
                  href="//www.elixir-europe.org/platforms/data/core-data-resources"
                  >ELIXIR core data resource</a
                >, and the repository of choice for many international science
                <a href="/Funders">Funders</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <alert-modal
        v-if="showModal && openedAlert"
        :alert="openedAlert"
        title="Save & create alert"
        @close="
          showModal = false
          openedAlert = null
        "
      />
      <modal v-else-if="showModal" @close="showModal = false">
        <template slot="title">Save & create alert</template>
        Please sign in to save and receive email updates for new results.
        <div class="button-group">
          <a href="/accounts/login" @click="registerRedirection"
            ><button>Continue</button></a
          >
        </div>
      </modal>
      <search-term-character-limit-modal
        v-if="showCharacterLimitModal"
        :query-length="query.length"
        context="Save & create alert"
        @close="showCharacterLimitModal = false"
      />
      <button id="feedback-btn" @click.prevent="showFeedbackModal = true">
        <i class="fas fa-comments"></i>
        Feedback
      </button>
      <button
        id="ask-me-later-container"
        style="display: none"
        @click.prevent="openSurveyLink()"
      >
        <i class="fas fa-clipboard-list"></i>
        <span id="survey-label"> Complete Survey</span>
        <span id="mobile-survey-label"> Survey</span>
      </button>
      <feedback-modal v-if="showFeedbackModal" @close="onFeedbackModalClosed" />
      <div v-if="showFeedbackConfirmation" id="feedback-confirmation">
        <confirmation @close="onFeedbackConfirmationClosed">
          Thank you for your feedback.
        </confirmation>
      </div>
    </div>
    <gdpr-banner v-if="showGdprBanner" @close="showGdprBanner = false" />
  </div>
</template>
<!-- <style lang="scss">
#warning-banner-container {
  background-color: $epmc-orange;
  text-align: left !important;
  .col-16 {
    display: flex;
    .notification {
      padding: ($base-unit * 3) 0;
    }
    .fa-times {
      padding-top: $base-unit * 3;
    }
  }
}
</style> -->
<style lang="scss" scoped>
#search-panel {
  background-image: url('~@/assets/epmc-bubbles.svg');
  background-size: cover;
  padding: ($base-unit * 6) 0;
  text-align: left;
  &.home-search-panel {
    padding-top: $base-unit * 16;
    padding-bottom: $base-unit * 12;
  }
  h1 {
    margin-top: 0;
    font-size: $base-unit * 6.5;
  }
  #article-count {
    font-size: $base-unit * 4.5;
    a:visited {
      color: $epmc-darker-blue;
    }
    #article-count-link {
      font-size: 18px;
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }
    @media only screen and (max-width: $breakpoint-extra-small) {
      display: none;
    }
  }
  #search-bar {
    input,
    button {
      margin: 0;
      height: $base-unit * 12;
      font-size: $base-unit * 4.5;
    }
    #banner--search-button {
      border-radius: 0;
      @media only screen and (max-width: $breakpoint-extra-small) {
        min-width: 0;
        #search-btn-text {
          display: none;
        }
      }
    }
    #search-bar-inputs {
      display: flex;
      #banner--search-input {
        flex-grow: 4;
      }
      #banner--alert-button {
        margin-left: $base-unit * 4;
        i {
          display: none;
        }
        @media only screen and (max-width: $breakpoint-extra-small) {
          min-width: 0;
          span {
            display: none;
          }
          i {
            display: inline;
          }
        }
      }
    }
    #separator {
      font-weight: bold;
      color: $epmc-darker-blue;
    }
    #betaAdvanceSearchLink {
      color: $epmc-dark-green;
    }
  }
  #features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: $base-unit * 8;
    .feature {
      box-sizing: border-box;
      border: thin solid lighten($epmc-darker-grey, 45%);
      padding: ($base-unit * 6) ($base-unit * 4);
      background-color: $epmc-white;
      width: 32%;
      @media only screen and (max-width: $breakpoint-extra-small) {
        width: 100%;
        &:not(:last-child) {
          margin-bottom: $base-unit * 4;
        }
      }
      text-align: center;
      img {
        height: $base-unit * 18;
      }
      h2 {
        margin-top: $base-unit * 2;
        font-size: $base-unit * 5.25;
      }
    }
  }
  .button-group {
    margin-top: $base-unit * 4;
  }
  #feedback-btn {
    position: fixed;
    bottom: 0;
    z-index: 6;
    right: $base-unit * 8;
    @media screen and (max-width: $breakpoint-small) {
      right: $base-unit * 6;
      z-index: 3;
    }
    border-top-left-radius: $base-unit * 4;
    box-shadow: ($base-unit * -0.25) ($base-unit * 0.25) $base-unit
      ($base-unit * 0.5) lighten($epmc-darker-grey, 30%);
  }
  #feedback-confirmation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #ask-me-later-container {
    display: inline-block;
    position: fixed;
    bottom: 0;
    right: $base-unit * 37.5;
    // left: 0;
    z-index: 6;
    border-top-left-radius: $base-unit * 4;
    box-shadow: ($base-unit * -0.25) ($base-unit * 0.25) $base-unit
      ($base-unit * 0.5) lighten($epmc-darker-grey, 30%);
    background-color: #417505;
    border-color: #417505;
    // height: 0px;
    text-align: right;
    #mobile-survey-label {
      display: none;
    }
    @media screen and (max-width: $breakpoint-extra-small) {
      z-index: 3;
      #survey-label {
        display: none;
      }
      #mobile-survey-label {
        display: inline;
      }
    }
    &:hover {
      border-color: #7e9c5b;
      background-color: #7e9c5b;
    }
  }
}
</style>
